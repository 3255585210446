import React from "react"
import Layout from "./layout"
import { Link , graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Image from "../components/image"

import * as list from "../components/css/blogList.module.scss"

export const query = graphql`
  query($limit: Int!, $skip: Int!) {
    site: site {
      id
      siteMetadata {
        author
        backgroundColor
        description
        domain
        shortName
        siteLanguage
        title
        url
        ogpImage
      }
    }

    articles: allWpPost (limit: $limit skip: $skip) {
      edges {
        node {
          id
          title
          date(fromNow: false, locale: "jp", formatString: "yyyy-MM-dd'T'HH:mm:ss")
          excerpt
          slug
          featuredImage {
            node {
              localFile {
                url
                childImageSharp {
                  fluid(maxWidth: 1024)  {
                    ...GatsbyImageSharpFluid
                  }
                  gatsbyImageData (
                    width: 800
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
          categories {
            nodes {
              name
              slug
            }
          }
        }
      }
    }
  }
`

const blogAll = ({ data, pageContext }) => {
  const site = data.site
  const cate = data.articles.edges[0].node.categories.nodes
  const paginatedPagesCount = pageContext.paginatedPagesCount
  const currentPage = pageContext.currentPage
  const isFirst = currentPage === 1
  const isLast = currentPage === paginatedPagesCount
  const prevPage = currentPage - 1 === 1 ? "" : (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()

  return (
    <Layout {...pageContext}>
      <div className={`${list.blogAll} ${list.wrap} cate-${cate[0].slug}`} data-cate={`${cate[0].slug}`} >

        <section class="ww-section-articles">
            <div class="articles-header-wrapper">
              <div class="articles-header-position">
                <h2>ワンダウォールの記事</h2>
              </div>
            </div>

            <ul className={`${list.articlesPosition} articles-position`} itemScope="" itemType="http://schema.org/BreadcrumbList">

              {data.articles.edges.map((edge) => {
                let isFeaturedImage = (edge.node.featuredImage) ? true : false
                let image = (isFeaturedImage) ? getImage(edge.node.featuredImage.node.localFile.childImageSharp.gatsbyImageData) : site.siteMetadata.ogpImage
          
                return (
                  <li className={`${list.articlesItem} articles-item`} itemProp="itemListElement" itemScope="" itemType="http://schema.org/ListItem" key={`${edge.node.id}`} >
                  <Link to={`/articles/${edge.node.slug}`} itemScope="" itemType="http://schema.org/Thing" itemProp="item">
                    <div className={`${list.itemWrapper} articles-item-wrapper`} >
                      <h4 className={`${list.itemHeader} article-header`} >{edge.node.categories.nodes[0].name}</h4>
                      <h3 className={`${list.itemCopy} article-copy`} itemProp="name">{edge.node.title}</h3>
                    </div>
                  </Link>
                  {isFeaturedImage && (
                    <figure className={`${list.featuredImageFrame}`}>
                    <GatsbyImage image={image} alt={edge.node.title} />
                    </figure>
                  )}
                  {!isFeaturedImage && (
                    <figure className={`${list.featuredImageFrame}`}>
                    <Image filename={`${image}`} alt={edge.node.title} />
                    </figure>
                  )}
                  </li>
                )
              })}
            </ul>
            <div className={`${list.pagenation} pagenation`}>
              {!isFirst && (
                <Link to={`/articles/${prevPage}`} rel="prev">
                  ← 前のページへ
                </Link>
              )}
              {!isLast && (
                  <Link to={`/articles/${nextPage}`} rel="next">
                    次のページへ →
                  </Link>
              )}
            </div>
        </section>
      </div>
    </Layout>
  )
}

export default blogAll
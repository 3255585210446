// extracted by mini-css-extract-plugin
export var contact = "globalNav-module--contact--deae2";
export var contactCaret = "globalNav-module--contactCaret--311f7";
export var contactDownloadVcf = "globalNav-module--contactDownloadVcf--7a62f";
export var contactItem = "globalNav-module--contactItem--7f987";
export var footerLegal = "globalNav-module--footerLegal--370ca";
export var globalFooter = "globalNav-module--globalFooter--5e97d";
export var globalNav = "globalNav-module--globalNav--2d2d3";
export var globalNavItem = "globalNav-module--globalNavItem--0d389";
export var personName = "globalNav-module--personName--a4b05";
export var safeArea = "globalNav-module--safeArea--e9f5c";
import React from "react"

import * as nav from "../components/css/globalNav.module.scss"

const Footer = () => (
  <footer id="ww-globalfooter" className={`${nav.globalFooter}`}>
    <div className={`${nav.safeArea} ww-safe-area`}>
      <div className={`${nav.footerLegal} ww-footer-legal`}>
        <div className="ww-footer-legal-copyright">
          Copyright &copy; 2004 - {new Date().getFullYear()} Wonderwall.net. All
          rights reserved.
        </div>
        <div className="ww-footer-legal-links">
          <a className="ww-footer-legal-link" href="https://goo.gl/forms/MVyp3DtW8WLzVO6k2">問い合わせ</a>
          <a className="ww-footer-legal-link" href="/articles/privacy-policy">プライバシーポリシー</a>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer

import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import VFC from "../images/icon_vfc-card.svg"
import Twitter from "../images/icon_twitter.svg"
import Facebook from "../images/icon_facebook.svg"
import Map from "../images/icon_map-marker.svg"

import * as nav from "../components/css/globalNav.module.scss"

const Header = ({ siteTitle }) => (
  <header>
    <nav id="ww-globalnav" className={`${nav.globalNav}`}>
      <div className={`ww-safe-area`}>
        <div className={`${nav.globalNavItem} ww-globalnav-position-1 ww-globalnav-item`}>
          <Link to="/" className={`company-identifier monochrome`}>
            <figure className={`company-mark-svg`}>
              <h1>{siteTitle}</h1>
              {/* <svg className="svgIcon">
                <use xlinkHref="#wonderwall-logo-monochrom"></use>
              </svg> */}
            </figure>
            <figure className={`company-logo-svg`}>
              {/* <svg className="svgIcon">
                <use xlinkHref="#wonderwall-logo-text"></use>
              </svg> */}
            </figure>
          </Link>
        </div>

        <div className={`${nav.globalNavItem} ww-globalnav-position-2 ww-globalnav-item`}>
          <div className={`contact-icon`}>
            <span>お問い合わせはこちら</span>
          </div>
        </div>
      </div>

      <aside className={`${nav.contact} ww-contact`} style={{ display: `none` }}>
        <span className={`${nav.contactCaret} ww-contact-caret`}></span>

        <ul className="ww-contact-wrapper" style={{ display: `none` }}>

            <li className={`${nav.contactItem} ww-contact-item contact-item-position-1`} key="1">
                <figure className="profile-image"></figure>
            </li>

            <li className={`${nav.contactItem} ww-contact-item contact-item-position-2`} key="2">
                <address className="contact-item-person-name">
                    <div className={`${nav.personName} person-name-main`}>
                        <span className={`person-name-first japanese`}></span>
                        <span className={`person-name-last japanese`}></span>
                    </div>
                    <div className={`${nav.personName} person-name-sub`}>
                        <span className={`person-name-first english`}></span>
                        <span className={`person-name-last english`}></span>
                    </div>
                </address>
            </li>

            <li className={`${nav.contactItem} ww-contact-item contact-item-position-3`} key="3">
                <address className="contact-item-email">
                    <a href="mailto:" className="email-address-complete">
                        <span className="email-address-name"></span>
                        <span className="email-address-atmark">@</span>
                        <span className="email-address-domain"></span>
                    </a>
                </address>
                <address className="contact-item-telephone">
                    <a href="tel:" className="telephone-number-complete">
                        <span className="telephone-number-1"></span>
                        <span className="telephone-number-2"></span>
                        <span className="telephone-number-3"></span>
                    </a>
                    <div className="telephone-type">
                        <span className="telephone-type-device"></span> / <span className="telephone-type-carrier"></span>
                    </div>
                </address>
                <a href="https://wonderwall.net/address/KenG-Imai.vcf" className={`${nav.contactDownloadVcf} ww-contact-wrapper ww-contact-download-vcf`} style={{ display: `none` }}>
                    <img src={VFC} alt="VFC Card" />
                    <span className="caption">連絡先データをダウンロード</span>
                </a>
            </li>

            <li className={`${nav.contactItem} ww-contact-item contact-item-position-4`} key="4">

            </li>

            <li className={`${nav.contactItem} ww-contact-item contact-item-position-5`} key="5">
                <address className="contact-item-address">
                    <span className="address-zip-postal-number"></span>
                    <span className="address-prefecture-name"></span>
                    <span className="address-city-name"></span>
                    <span className="address-area-name"></span>
                </address>
                <a className="contact-item-map-url" href="/">
                    <img src={Map} alt="Map Marker Icon" />
                    <span className="caption">地図を表示</span>
                </a>
            </li>

            <li className={`${nav.contactItem} ww-contact-item contact-item-position-6`} key="6">
                <address className="contact-item-sns">
                    <a className="sns-twitter" href="/">
                        <img src={Twitter} alt="Twitter Icon" />
                        <span className="caption"></span>
                    </a>
                    <a className="sns-facebook" href="/">
                        <img src={Facebook} alt="Facebook Icon" />
                        <span className="caption"></span>
                    </a>
                </address>
            </li>
        </ul>
        <div className={`${nav.contactItem} ww-contact-form contact-item-position-7`} style={{ display: `block` }}>
            <p className="caption">名刺交換させていただいた方には、名刺のQRコードから連絡先情報が表示されます。その他の方は下記のフォームからお問い合わせください。</p>
            <address className="contact-item-google-form">
                <a href="https://goo.gl/forms/MVyp3DtW8WLzVO6k2" className="google-form">問い合わせフォーム</a>
            </address>
            <p className="caption">《PDFリーフレット配付中》<br />名刺と一緒にお渡ししているワンダーウォールのリーフレットをPDFにしました。<a href="images/wonderwall-leaflet.pdf">こちらからダウンロード</a>してください。</p>
        </div>

    </aside>
    </nav>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
